const state = {
    messages: [],
    transcribedTexts: {},
  };
  
  const mutations = {
    ADD_MESSAGE(state, message) {
      state.messages.push(message);
    },
    SET_TRANSCRIBED_TEXT(state, { context, text }) {
      state.transcribedTexts = { ...state.transcribedTexts, [context]: text };
    },
  };
  
  const actions = {
    addMessage({ commit }, message) {
      commit('ADD_MESSAGE', message);
    },
    setTranscribedText({ commit }, { context, text }) {
      commit('SET_TRANSCRIBED_TEXT', { context, text });
    },
  };
  
  const getters = {
    allMessages: (state) => state.messages,
    transcribedText: (state) => (context) => state.transcribedTexts[context] || '',
  };
  
  export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
  };