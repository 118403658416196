<template>
  <ion-app>
    <ion-menu content-id="main-content" ref="menu" :swipe-gesture="false">
      <ion-header>
        <ion-toolbar>
          <ion-title>Menu</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-list>
          <ion-item @click="navigateAndCloseMenu('MyAccount')" :disabled="!currentUser">
            <ion-label>Account</ion-label>
          </ion-item>
          <ion-item @click="navigateAndCloseMenu('MyDashboard')" :disabled="!currentUser || !currentUser.emailVerified">
            <ion-label>Dashboard</ion-label>
          </ion-item>
          <ion-item @click="navigateAndCloseMenu('MyPractice')" :disabled="!currentUser || !currentUser.emailVerified">
            <ion-label>Practice</ion-label>
          </ion-item>
          <ion-item @click="navigateAndCloseMenu('FreeStyleChat')" :disabled="!currentUser || !currentUser.emailVerified">
            <ion-label>Freestyle</ion-label>
          </ion-item>
          <ion-item @click="navigateAndCloseMenu('ExerciseManager')" :disabled="!currentUser || !currentUser.emailVerified">
            <ion-label>Exercise Manager</ion-label>
          </ion-item>
          <ion-item @click="navigateAndCloseMenu('MySettings')" :disabled="!currentUser || !currentUser.emailVerified">
            <ion-label>Settings</ion-label>
          </ion-item>
        </ion-list>
      </ion-content>
    </ion-menu>

    <ion-page id="main-content">
      <ion-header>
        <ion-toolbar>
          <ion-buttons slot:start>           
            <ion-menu-button></ion-menu-button>            
            <ion-title>Lobster</ion-title>
          </ion-buttons>                  
          <install-p-w-a></install-p-w-a> 
        </ion-toolbar>
      </ion-header>

      <ion-content class="ion-padding">
        <template v-if="!currentUser">
          <my-account @user-signed-in="handleUserSignedIn"></my-account>
        </template>
        <template v-else-if="!currentUser.emailVerified">
          <my-account></my-account>
        </template>
        <template v-else-if="isLoading">
          <ion-spinner name="crescent"></ion-spinner>
          <p>Loading your data...</p>
        </template>
        <template v-else>
          <component :is="currentPage"></component>
        </template>
      </ion-content>
    </ion-page>
  </ion-app>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { 
  IonApp, IonContent, IonHeader, IonToolbar, IonTitle, 
  IonMenu, IonList, IonItem, IonLabel, IonPage, IonButtons, 
  IonMenuButton, IonSpinner, menuController
} from '@ionic/vue';
// import { initializeApp } from 'firebase/app';
import { onAuthStateChanged } from 'firebase/auth';
import MyAccount from './components/MyAccount.vue';
import InstallPWA from './components/InstallPWA.vue';
import MyDashboard from './components/MyDashboard.vue';
import MyPractice from './components/MyPractice.vue';
import MySettings from './components/MySettings.vue';
import FreeStyleChat from './components/FreeStyleChat.vue'; 
import ExerciseManager from './components/ExerciseManager.vue';
import { auth } from '../firebase'

export default {
  name: 'App',
  components: {
    IonApp, IonContent, IonHeader, IonToolbar, IonTitle, 
    IonMenu, IonList, IonItem, IonLabel, IonPage, IonButtons, 
    IonMenuButton, IonSpinner, MyAccount, InstallPWA, MyDashboard, MyPractice, MySettings, FreeStyleChat, ExerciseManager 
  },
  setup() {
    const store = useStore();
    const currentPage = ref('MyAccount');
    const isLoading = ref(false);

    // const firebaseConfig = {
    //   apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    //   authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    //   projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    //   storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    //   messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    //   appId: process.env.VUE_APP_FIREBASE_APP_ID,
    //   measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
    // };

    // initializeApp(firebaseConfig);
    // const auth = getAuth();

    const currentUser = computed(() => store.getters['account/currentUser']);

    const navigateAndCloseMenu = async (page) => {
      if (currentUser.value && (currentUser.value.emailVerified || page === 'MyAccount')) {
        currentPage.value = page;
        await menuController.close();
      }
    };

    const handleUserSignedIn = () => {
      if (currentUser.value && currentUser.value.emailVerified) {
        currentPage.value = 'MyPractice';
      }
    };
    
    onMounted(async () => { 
      onAuthStateChanged(auth, async (user) => {
        await store.dispatch('account/updateUser', user);
        if (user && user.emailVerified) {
          isLoading.value = true;
          try {
            await Promise.all([
              store.dispatch('settings/fetchSettings'),
              store.dispatch('usageLimits/fetchLimits')
            ]);
            currentPage.value = 'MyPractice';
          } catch (error) {
            console.error('Error loading user data:', error);
            // Handle error (e.g., show error message to user)
          } finally {
            isLoading.value = false;
          }
        } else {
          currentPage.value = 'MyAccount';
        }
      });
    });

    return {
      currentUser,
      currentPage,
      navigateAndCloseMenu,
      handleUserSignedIn,
      isLoading
    };
  }
};
</script>

<style scoped>
ion-spinner {
  display: block;
  margin: auto;
  margin-top: 20px;
}

p {
  text-align: center;
  margin-top: 10px;
}
</style>