<template>
  <div class="practice">
    <!-- <h1>Practice</h1> -->
    <ion-button size="small" color="danger" @click="initializeAudio" v-if="!audioInitialized">Initialize Audio</ion-button>
    <!-- Exercise selection -->
    <ion-list>
      <ion-item>
        <ion-select label="Exercise" label-placement="stacked" placeholder="Select exercise" v-model="selectedExercise" @ionChange="handleExerciseSelection">
          <ion-select-option v-for="exercise in userExercises" :key="exercise.id" :value="exercise">
            {{ exercise.name }}
          </ion-select-option>
        </ion-select>
      </ion-item>
    </ion-list>

    <!-- Display current mode -->
    <!-- <p v-if="currentMode">Current Mode: {{ currentMode }}</p> -->

    <!-- Mode switching buttons -->
    <ion-button size="small" color="primary" fill="outline" v-if="currentMode === 'Learning'" @click="confirmModeSwitch('Practice')">
      Go to Practice
    </ion-button>
    <ion-button size="small" color="primary" fill="outline" v-if="currentMode === 'Practice'" @click="confirmModeSwitch('Feedback')">
      Go to Feedback
    </ion-button>
    <ion-button size="small" color="primary" fill="outline" v-if="currentMode === 'Feedback'" @click="confirmModeSwitch('Learning')">
      Go to Learning
    </ion-button>


    <!-- <UniversalVolumeControl /> -->

    <!-- MyChat component -->
    <MyChat 
      v-if="chatStarted"
      :context="context"
      @error="handleError"
    />
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { IonList, IonItem,  IonSelect, IonSelectOption, IonButton, alertController } from '@ionic/vue';//IonLabel,
import MyChat from './MyChat.vue';
import AudioService from '@/services/audioService';
// import UniversalVolumeControl from './UniversalVolumeControl.vue';
import VolumeControlService from '@/services/volumeControlService';

export default {
  name: 'MyPractice',
  components: { 
    IonList, 
    IonItem, 
    // IonLabel, 
    IonSelect, 
    IonSelectOption,
    IonButton,
    MyChat,
    // UniversalVolumeControl
  },
  emits: ['error'],
  setup(props, { emit }) {
    const store = useStore();
    const context = 'practice';

    // const exercises = computed(() => store.getters['exercise/allExercises']);
    const userExercises = computed(() => store.getters['exercise/userExercises']);
    const selectedExercise = computed({
      get: () => store.getters['exercise/selectedExercise'],
      set: (value) => store.dispatch('exercise/selectExercise', value)
    });
    const chatStarted = computed(() => store.getters['exercise/chatStarted'](context));
    const currentMode = computed(() => store.getters['exercise/currentMode']);
    const audioInitialized = ref(false);

    onMounted(async () => {
      audioInitialized.value = AudioService.isInitialized();
      VolumeControlService.init();
      await store.dispatch('exercise/loadUserExercises');
    });

    const handleExerciseSelection = async () => {
      if (selectedExercise.value) {
        await store.dispatch('exercise/startNewChat', { 
          context: context, 
          exerciseId: selectedExercise.value.id 
        });
      }
    };

    const confirmModeSwitch = async (newMode) => {
      const alert = await alertController.create({
        header: 'Confirm Mode Switch',
        message: `Are you sure you want to switch to ${newMode} mode? Your current progress will be saved.`,
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
          },
          {
            text: 'Switch',
            handler: () => switchMode(newMode)
          }
        ]
      });

      await alert.present();
    };

    const switchMode = async (newMode) => {
      if (selectedExercise.value) {
        await store.dispatch('exercise/switchExerciseMode', {
          context: context, 
          exerciseId: selectedExercise.value.id,
          newMode: newMode
        });
      }
    };

    const handleError = (error) => {
      console.error('Error in MyChat component:', error);
      // Handle the error as needed
    };

    const initializeAudio = async () => {
      try {
        await AudioService.initialize();
        audioInitialized.value = true;
      } catch (error) {
        console.error('Failed to initialize audio:', error);
        emit('error', 'Failed to initialize audio');
      }
    };

    return {      
      userExercises,
      selectedExercise,
      chatStarted,
      currentMode,
      context,
      handleExerciseSelection,
      confirmModeSwitch,
      handleError,
      audioInitialized,
      initializeAudio,
    };
  }
};
</script>

<style scoped>
.practice {
  display: flex;
  flex-direction: column;
  height: 100%;
}

ion-button {
  margin-top: 10px;
}
</style>