import { ref } from 'vue';
import { Capacitor } from '@capacitor/core';
import { VolumeButtons } from '@capacitor-community/volume-buttons';
import AudioService from './audioService';

class VolumeControlService {
  constructor() {
    this.volume = ref(1);
    this.isNative = Capacitor.isNativePlatform();
    this.watchId = null;
  }

  async init() {
    const currentVolume = AudioService.getCurrentVolume();
    if (currentVolume !== undefined) {
      this.volume.value = currentVolume;
    }

    if (this.isNative) {
      try {
        const options = {
          disableSystemVolumeHandler: Capacitor.getPlatform() === 'ios',
          suppressVolumeIndicator: Capacitor.getPlatform() === 'android'
        };
        this.watchId = await VolumeButtons.watchVolume(options, this.handleVolumeButtonPress.bind(this));
        console.log('Volume button watch initialized');
      } catch (error) {
        console.error('Failed to initialize volume button watch:', error);
      }
    }
  }

  handleVolumeButtonPress(result) {
    if (result.direction === 'up') {
      this.incrementVolume();
    } else if (result.direction === 'down') {
      this.decrementVolume();
    }
  }

  setVolume(value) {
    this.volume.value = Math.max(0, Math.min(1, value));
    AudioService.setVolume(this.volume.value);
  }

  incrementVolume(step = 0.1) {
    this.setVolume(this.volume.value + step);
  }

  decrementVolume(step = 0.1) {
    this.setVolume(this.volume.value - step);
  }

  getCurrentVolume() {
    return this.volume.value;
  }

  async cleanup() {
    if (this.isNative && this.watchId) {
      await VolumeButtons.clearWatch();
    }
  }
}

export default new VolumeControlService();