<template>  
  <div class="my-chat" ref="myChatContainer">    
    <div v-if="chatStarted" class="chat-area" ref="chatContainer" :style="{ height: chatAreaHeight + 'px' }">
      <template v-for="message in chatHistory" :key="message.id">
        <div v-if="message" class="message" :class="message.role">
          <div v-if="message.role === 'user'">{{ message.content }}</div>
          <div v-else class="markdown-body" v-html="renderedContent(message.content)"></div>
          <div v-if="message.role === 'assistant'" class="message-controls">
            <ion-button size="small" color="primary" fill="outline" @click="togglePlayback(message.id)" v-if="audioAvailable[message.id]">
              {{ playbackState[message.id] === 'playing' ? 'Pause' : 'Play' }}
            </ion-button>
            <ion-button size="small" color="primary" fill="outline" @click="stopPlayback(message.id)" v-if="playbackState[message.id] === 'playing' || playbackState[message.id] === 'paused'">
              Stop
            </ion-button>
            <p v-if="synthesisError[message.id]" class="error-message">{{ synthesisError[message.id] }}</p>
            <div v-if="isCurrentlySynthesizing(message.id)">Synthesizing...</div>
          </div>
        </div>
      </template>
    </div>


    <VoiceRecording
      v-if="chatStarted"
      :context="context"
      @transcriptionComplete="handleTranscription"
      @error="handleError"
    />

    <p v-if="transcribedText">Transcribed Text: {{ transcribedText }}</p>
    <p v-if="error" class="error-message">{{ error }}</p>
  </div>
</template>

<script>
import { ref, computed, onMounted, nextTick, watch, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import { IonButton } from '@ionic/vue';
import VoiceRecording from './VoiceRecording.vue';
import * as SpeechSDK from 'microsoft-cognitiveservices-speech-sdk';
import { storeAudioMessage, checkAudioExists } from '@/services/indexedDBService'; //storeAudioMessage
import { marked } from 'marked';
import hljs from 'highlight.js';
import AudioService from '@/services/audioService';

export default {
  name: 'MyChat',
  components: { 
    VoiceRecording,
    IonButton
  },
  props: {
    context: {
      type: String,
      required: true
    }
  },
  emits: ['error'],
  setup(props, { emit }) {
    const store = useStore();
    const chatContainer = ref(null);
    const myChatContainer = ref(null);
    const chatAreaHeight = ref(0);
    const synthesizer = ref(null);
    const audioAvailable = ref({});
    const synthesisError = ref({});
    const currentlySynthesizing = ref(new Set());    
    const playbackState = ref({});

    const chatStarted = computed(() => store.getters['exercise/chatStarted'](props.context));
    const chatHistory = computed(() => store.getters['exercise/chatHistory'](props.context));
    const lastMessage = computed(() => store.getters['exercise/lastMessage'](props.context));
    const error = computed(() => store.getters['exercise/error']);
    const transcribedText = computed(() => store.getters['chat/transcribedText'](props.context));
    const settings = computed(() => store.getters['settings/getSettings'])

    const isTtsAvailable = computed(() => store.getters['usageLimits/isTtsAvailable']);    

    marked.setOptions({
      highlight: function(code, lang) {
        const language = hljs.getLanguage(lang) ? lang : 'plaintext';
        return hljs.highlight(code, { language }).value;
      },
      langPrefix: 'hljs language-'
    });

    const renderedContent = (content) => {
      return marked(content);
    };

    const stripMarkdown = (text) => {
      return text
        .replace(/#+\s/g, '')
        .replace(/(\*\*|__)(.*?)\1/g, '$2')
        .replace(/(\*|_)(.*?)\1/g, '$2')
        .replace(/\[([^\]]+)\]\(([^)]+)\)/g, '$1')
        .replace(/`{1,3}[^`\n]+`{1,3}/g, '')
        .replace(/^\s*[-+*]\s/gm, '')
        .replace(/^\s*(\d+)\.\s/gm, '$1. ')
        .replace(/\n/g, ' ')
        .replace(/\s+/g, ' ')
        .trim();
    };
   
    const initializeSpeechSynthesizer = () => {
      const speechConfig = SpeechSDK.SpeechConfig.fromSubscription(
        process.env.VUE_APP_AZURE_SPEECH_KEY,
        process.env.VUE_APP_AZURE_SPEECH_REGION
      );
      speechConfig.speechSynthesisVoiceName = settings.value.aiVoice ?? "en-US-JennyNeural";
      
      // Configure audio output format to MP3
      speechConfig.speechSynthesisOutputFormat = SpeechSDK.SpeechSynthesisOutputFormat.Raw24Khz16BitMonoPcm;

      // Create a PullAudioOutputStream
      const pullStream = SpeechSDK.AudioOutputStream.createPullStream();
      
      // Create AudioConfig with custom audio output stream
      const audioConfig = SpeechSDK.AudioConfig.fromStreamOutput(pullStream);

      synthesizer.value = new SpeechSDK.SpeechSynthesizer(speechConfig, audioConfig);
    };

    const speakMessage = async (text, messageId) => {
      if (!synthesizer.value) {
        console.error('Speech synthesizer not initialized');
        return;
      }

      try {
        currentlySynthesizing.value.add(messageId);
        const strippedText = stripMarkdown(text);
        
        console.log('MyChat: Starting speech synthesis for message:', messageId);
        const ttsCharacters = strippedText.length;
        const ssml = `
          <speak version="1.0" xmlns="http://www.w3.org/2001/10/synthesis" xml:lang="${settings.value.tergetLanguage??'en-US'}">
            <voice name="${settings.value.aiVoice??'en-US-JennyNeural'}">
              ${strippedText}
            </voice>
          </speak>
        `;

        // await AudioService.prepareStreamingAudio(messageId);
        //let fullAudioBuffer = new Uint8Array(0);

        await AudioService.resetAndPlayNew(messageId); // Reset the AudioService for a new message

        const pushStream = new SpeechSDK.PushAudioOutputStreamCallback();
        pushStream.write = async (audioChunk) => { 
          if(AudioService.currentMessageId !== messageId)         
            pushStream.close();

          await AudioService.appendAudioChunk(audioChunk, messageId);
        };

        pushStream.close = () => {
          console.log('MyChat: Custom audio stream closed');
            // AudioService.completeStream();
            //const audioBlob = new Blob([fullAudioBuffer], { type: 'audio/mpeg' });
            // storeAudioMessage(messageId, audioBlob)
            //   .then(() => {
            //     audioAvailable.value[messageId] = true;
            //     console.log('Complete audio stored and available for message:', messageId);
            //   })
            //   .catch(error => {
            //     console.error('Error storing complete audio message:', error);
            //   });
          };

          return new Promise((resolve, reject) => {
          synthesizer.value.speakSsmlAsync(
            ssml,
            async result => {
              if (result.reason === SpeechSDK.ResultReason.SynthesizingAudioCompleted) {
                console.log("MyChat: Speech synthesis completed for message:", messageId);
                pushStream.close();                
                console.log("Audio data format:", result.audioData);
                console.log("Audio data byte length:", result.audioData.byteLength);
                console.log("First few bytes:", new Uint8Array(result.audioData.slice(0, 16)));
                pushStream.close();

                // Increment TTS usage
                await store.dispatch('usageLimits/incrementTtsUsage', ttsCharacters);

                await storeAudioMessage(messageId, result.audioData)                
                audioAvailable.value[messageId] = true;
                console.log('MyChat: Complete audio stored and available for message:', messageId);
                resolve();               
                            
              } else if (result.reason === SpeechSDK.ResultReason.Canceled) {
                const cancellation = SpeechSDK.CancellationDetails.fromResult(result);
                console.error('MyChat: Speech synthesis canceled:', cancellation.reason);
                if (cancellation.reason === SpeechSDK.CancellationReason.Error) {
                  console.error('MyChat: Error details:', cancellation.errorDetails);
                }
                pushStream.close();
                reject(new Error('MyChat: Speech synthesis canceled: ' + cancellation.reason));
              }
            },
            error => {
              console.error("MyChat: Speech synthesis error:", error);
              pushStream.close();
              reject(error);
            },
            pushStream
          );
        });

      } catch (error) {
        console.error("MyChat: Error in speech synthesis:", error);
        synthesisError.value[messageId] = error.message || 'Failed to synthesize speech';
        audioAvailable.value[messageId] = false;
      } finally {
        currentlySynthesizing.value.delete(messageId);
      }
    };

    const togglePlayback = async (messageId) => {
      if (AudioService.isAudioPlaying(messageId)) {
        await AudioService.pause();
      } else {  
        console.log(playbackState.value[messageId]);      
        if(playbackState.value[messageId] === 'paused')
        {
            await AudioService.play();
        }
        else{
          await AudioService.resetAndPlayNew(messageId);
          await AudioService.playStoredAudio(messageId);   
        }
      }
    };

    const stopPlayback = (messageId) => {
      if (AudioService.getCurrentMessageId() === messageId) {
        AudioService.stop();
      }
    }; 

    const isCurrentlySynthesizing = (messageId) => {
      return currentlySynthesizing.value.has(messageId);
    };

    const loadStoredAudio = async (messageId) => {
      try {
        const exists = await checkAudioExists(messageId);
        if (exists) {
          audioAvailable.value[messageId] = true;
        }
      } catch (error) {
        console.error('Error checking audio availability:', error);
      }
    };

    const checkAudioAvailability = async () => {
      for (const message of chatHistory.value) {
        if (message && message.role === 'assistant') {
          await loadStoredAudio(message.id);
        }
      }
    };

    const calculateChatAreaHeight = () => {
      if (myChatContainer.value && chatContainer.value) {
        const containerHeight = myChatContainer.value.clientHeight;
        const chatTop = chatContainer.value.offsetTop;
        const otherElementsHeight = 100;
        chatAreaHeight.value = containerHeight - chatTop - otherElementsHeight;
      }
    };

    const scrollToBottom = () => {
      nextTick(() => {
        if (chatContainer.value) {
          chatContainer.value.scrollTop = chatContainer.value.scrollHeight;
        }
      });
    };

    onMounted(async () => {
      calculateChatAreaHeight();
      window.addEventListener('resize', calculateChatAreaHeight);
      initializeSpeechSynthesizer();
      checkAudioAvailability();

      // Add event listeners for AudioService events
      AudioService.on('play', (messageId) => {
        playbackState.value[messageId] = 'playing';
        console.log("MyChat: AudioService started playing for message:", messageId);
      });

      AudioService.on('pause', (messageId) => {
        playbackState.value[messageId] = 'paused';
        console.log("MyChat: AudioService paused for message:", messageId);
      });

      AudioService.on('stop', (messageId) => {
        playbackState.value[messageId] = 'stopped';
        console.log("MyChat: AudioService stopped for message:", messageId);
      });

      AudioService.on('bufferUpdated', (duration) => {
        // Update progress bar or other UI elements if needed
        console.log("bufferUpdated duration:", duration);
      });

      AudioService.on('error', (message) => {
        console.error("MyChat: AudioService error:", message);
        emit('error', message);
      });     
    });

    onUnmounted(() => {
      window.removeEventListener('resize', calculateChatAreaHeight);
      if (synthesizer.value) {
        synthesizer.value.close();
      }
      AudioService.stop();
      console.log("stopped 2");
      AudioService.removeAllListeners();      
    });    

    watch(lastMessage, async (newMessage) => {  
      if (newMessage && newMessage.role === 'assistant' && newMessage.id && isTtsAvailable.value) {          
        await speakMessage(newMessage.content, newMessage.id);
      }
      scrollToBottom();
    });

    const handleTranscription = async (text) => {
      await store.dispatch('exercise/sendMessage', { context: props.context, message: text });
      store.dispatch('chat/setTranscribedText', { context: props.context, text: '' });
    };

    const handleError = (error) => {
      console.error('Error in MyChat:', error);
      store.commit('exercise/SET_ERROR', `Error: ${error}`);
      store.dispatch('chat/setTranscribedText', { context: props.context, text: '' });
      emit('error', error);
    };

    return {
      chatStarted,
      chatHistory,
      error,
      transcribedText,
      chatContainer,
      myChatContainer,
      chatAreaHeight,
      handleTranscription,
      handleError,
      togglePlayback,
      stopPlayback,
      renderedContent,
      audioAvailable,
      synthesisError,
      isCurrentlySynthesizing,      
      playbackState,      
    };
  }
};
</script>

<style scoped>
  @import '~highlight.js/styles/github.css';
  .my-chat {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .chat-area {
    flex-grow: 1;
    overflow-y: auto;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .message {
    margin: 10px 0;
    padding: 10px;
    border-radius: 10px;
    max-width: 80%;
  }
  
  .message.user {
    background-color: #e1ffc7;
    align-self: flex-end;
    margin-left: auto;
  }
  
  .message.assistant {
    background-color: #f0f0f0;
    align-self: flex-start;
    margin-right: auto;
  }

  .error-message {
    color: red;
    margin-top: 10px;
  }

  .message-controls {
    margin-top: 5px;
  }

  .message-controls button {
    margin-right: 5px;
    padding: 2px 5px;
    font-size: 0.8em;
  }

  /* Styles for rendered markdown */
  .markdown-body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 16px;
    line-height: 1.5;
    word-wrap: break-word;
  }

  .markdown-body h1 { font-size: 2em; margin-top: 0.67em; margin-bottom: 0.67em; font-weight: bold; }
  .markdown-body h2 { font-size: 1.5em; margin-top: 0.83em; margin-bottom: 0.83em; font-weight: bold; }
  .markdown-body h3 { font-size: 1.17em; margin-top: 1em; margin-bottom: 1em; font-weight: bold; }
  .markdown-body h4 { font-size: 1em; margin-top: 1.33em; margin-bottom: 1.33em; font-weight: bold; }
  .markdown-body h5 { font-size: 0.83em; margin-top: 1.67em; margin-bottom: 1.67em; font-weight: bold; }
  .markdown-body h6 { font-size: 0.67em; margin-top: 2.33em; margin-bottom: 2.33em; font-weight: bold; }

  .markdown-body p { margin-top: 1em; margin-bottom: 1em; }

  .markdown-body strong { font-weight: bold; }
  .markdown-body em { font-style: italic; }

  .markdown-body ul, .markdown-body ol { padding-left: 2em; margin-top: 1em; margin-bottom: 1em; }
  .markdown-body li { margin-bottom: 0.5em; }

  .markdown-body blockquote {
    padding: 0 1em;
    color: #6a737d;
    border-left: 0.25em solid #dfe2e5;
    margin: 1em 0;
  }

  .markdown-body code {
    padding: 0.2em 0.4em;
    margin: 0;
    font-size: 85%;
    background-color: rgba(27,31,35,0.05);
    border-radius: 3px;
    font-family: SFMono-Regular, Consolas, 'Liberation Mono', Menlo, monospace;
  }

  .markdown-body pre {
    padding: 16px;
    overflow: auto;
    font-size: 85%;
    line-height: 1.45;
    background-color: #f6f8fa;
    border-radius: 3px;
    margin-top: 1em;
    margin-bottom: 1em;
  }

  .markdown-body pre code {
    padding: 0;
    font-size: 100%;
    background-color: transparent;
  }

  .markdown-body img {
    max-width: 100%;
    height: auto;
  }

  .markdown-body a {
    color: #0366d6;
    text-decoration: none;
  }

  .markdown-body a:hover {
    text-decoration: underline;
  }

  .markdown-body table {
    border-collapse: collapse;
    margin: 1em 0;
    width: 100%;
  }

  .markdown-body table th, .markdown-body table td {
    padding: 6px 13px;
    border: 1px solid #dfe2e5;
  }

  .markdown-body table tr:nth-child(2n) {
    background-color: #f6f8fa;
  }
</style>