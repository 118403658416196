<template>
    <div class="free-style-chat">
      <ion-button size="small" color="danger" @click="initializeAudio" v-if="!audioInitialized">
        Initialize Audio
      </ion-button>
  
      <MyChat 
        v-if="chatStarted"
        :context="context"
        @error="handleError"
      />
    </div>
  </template>
  
  <script>
  import { ref, computed, onMounted } from 'vue';
  import { useStore } from 'vuex';
  import { IonButton } from '@ionic/vue';
  import MyChat from './MyChat.vue';
  import AudioService from '@/services/audioService';
  
  export default {
    name: 'FreeStyleChat',
    components: { 
      IonButton,
      MyChat
    },
    emits: ['error'],
    setup(props, { emit }) {
      const store = useStore();
      const context = 'free_style';
  
      const chatStarted = computed(() => store.getters['exercise/chatStarted'](context));
      const audioInitialized = ref(false);
  
      onMounted(() => {
        audioInitialized.value = AudioService.isInitialized();
        startFreeStyleChat();
      });
  
      const startFreeStyleChat = async () => {
        await store.dispatch('exercise/startNewFreeStyleChat', { context });
      };
  
      const handleError = (error) => {
        console.error('Error in MyChat component:', error);
        emit('error', error);
      };
  
      const initializeAudio = async () => {
        try {
          await AudioService.initialize();
          audioInitialized.value = true;
        } catch (error) {
          console.error('Failed to initialize audio:', error);
          emit('error', 'Failed to initialize audio');
        }
      };
  
      return {
        chatStarted,
        context,
        handleError,
        audioInitialized,
        initializeAudio,
      };
    }
  };
  </script>
  
  <style scoped>
  .free-style-chat {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  ion-button {
    margin-top: 10px;
  }
  </style>