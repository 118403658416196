// src/store/modules/settings.js
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';

const state = {
  nativeLanguage: null,
  nativeLanguageName: null,
  targetLanguage: "en-US",
  targetLanguageName: "English (United States)",
  languageLevel: "Beginner",
  aiVoice: "en-US-JennyNeural",
  provideFeedback: false,
};

const mutations = {
  SET_SETTINGS(state, settings) {
    Object.assign(state, settings);
  },
  SET_TARGET_LANGUAGE(state, { code, name }) {
    state.targetLanguage = code;
    state.targetLanguageName = name;
  },
  SET_NATIVE_LANGUAGE(state, { code, name }) {
    state.nativeLanguage = code;
    state.nativeLanguageName = name;
  }
};

const actions = {
  async fetchSettings({ commit, rootState }) {
    const userId = rootState.account.user.uid;
    const userDocRef = doc(db, 'users', userId);
    const userDocSnap = await getDoc(userDocRef);
    
    if (userDocSnap.exists() && userDocSnap.data().settings) {
      commit('SET_SETTINGS', userDocSnap.data().settings);
    }
  },

  async updateSettings({ commit, rootState }, settings) {
    const userId = rootState.account.user.uid;
    const userDocRef = doc(db, 'users', userId);
    await setDoc(userDocRef, { settings }, { merge: true });
    commit('SET_SETTINGS', settings);
  },

  async setTargetLanguage({ commit, dispatch }, { code, name }) {
    commit('SET_TARGET_LANGUAGE', { code, name });
    // Update Firestore with both code and name
    await dispatch('updateSettings', { 
      targetLanguage: code, 
      targetLanguageName: name 
    });
  },

  async setNativeLanguage({ commit, dispatch }, { code, name }) {
    commit('SET_NATIVE_LANGUAGE', { code, name });
    // Update Firestore with both code and name
    await dispatch('updateSettings', { 
        nativeLanguage: code, 
        nativeLanguageName: name 
    });
  },  
};

const getters = {
  getSettings: (state) => state,
  getAIVoice: (state) => state.aiVoice,
  getTargetLanguage: (state) => state.targetLanguage,
  getTargetLanguageName: (state) => state.targetLanguageName,
  getNativeLanguage: (state) => state.nativeLanguage,
  getNativeLanguageName: (state) => state.nativeLanguageName,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};