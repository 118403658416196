import { EventEmitter } from 'events';
import { getAudioMessage } from './indexedDBService';

class AudioService extends EventEmitter {
    constructor(options = {}) {
        super();        
        const defaults = {
            encoding: '16bitInt',
            channels: 1,  // Mono audio
            sampleRate: 24000,  // 24 kHz
            flushingTime: 1000,  // Adjust this if needed for smoother playback
        };
        this.options = Object.assign({}, defaults, options);
        this.samples = new Float32Array();
        this.maxValue = this.getMaxValue();
        this.typedArray = this.getTypedArray(); 
        this.currentMessageId = null;    
        this.initialized = false;
        this.volume = 1;
    }

    isInitialized() {
        return this.initialized;
    }

    async initialize(){
      this.createContext();
      this.flushInterval = setInterval(await this.flush.bind(this), this.options.flushingTime);
      this.initialized = true;
      console.log("AudioService: Initialized");
      
      if (this.audioContext.state === 'suspended') {
        await this.audioContext.resume();
      }
      this.state = 'ready';
      this.emit('ready');
    }

    async resetAndPlayNew(messageId) {
      this.stop();   
      if(this.currentMessageId)   
        this.emit('stop', this.currentMessageId);      
      this.currentMessageId = messageId;      
      this.state = 'ready';
      this.emit('ready');
  }

    getMaxValue() {
        const encodings = {
            '8bitInt': 128,
            '16bitInt': 32768,
            '32bitInt': 2147483648,
            '32bitFloat': 1
        };
        return encodings[this.options.encoding] || encodings['16bitInt'];
    }

    getTypedArray() {
        const typedArrays = {
            '8bitInt': Int8Array,
            '16bitInt': Int16Array,
            '32bitInt': Int32Array,
            '32bitFloat': Float32Array
        };
        return typedArrays[this.options.encoding] || typedArrays['16bitInt'];
    }

    createContext() {
        this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
        this.gainNode = this.audioContext.createGain();
        this.gainNode.gain.value = this.volume;
        this.gainNode.connect(this.audioContext.destination);
        this.startTime = this.audioContext.currentTime;
        this.emit('initialized');
    }

    

    appendAudioChunk(audioChunk, messageId) {

        if(!this.currentMessageId)
            console.warn('AudioService: currentMessageId in not set');

        if(this.currentMessageId !== messageId)
            return;

        let audioData = this.convertAudioChunks(audioChunk);

        if (!(audioData instanceof Float32Array)) {
            console.warn('AudioService: Expected Float32Array, converting input.');
            audioData = this.convertToFloat32(audioData);
        }
        const newBuffer = new Float32Array(this.samples.length + audioData.length);
        newBuffer.set(this.samples);
        newBuffer.set(audioData, this.samples.length);
        this.samples = newBuffer;
        this.emit('bufferUpdated', this.samples.length / this.options.sampleRate);
    }

    convertAudioChunks(audioChunk)
    {
      console.log("AudioService: Received audio chunk, type:", typeof audioChunk, "length:", audioChunk.byteLength);

          // Log the structure of the audioChunk for debugging
          console.log("AudioChunk structure:", JSON.stringify(audioChunk, (key, value) => 
              ArrayBuffer.isView(value) ? `TypedArray(${value.length})` : value
          ));

          let int16Array;
          if (audioChunk instanceof ArrayBuffer) {
              int16Array = new Int16Array(audioChunk);
          } else if (audioChunk.buffer instanceof ArrayBuffer) {
              int16Array = new Int16Array(audioChunk.buffer);
          } else if (audioChunk instanceof Uint8Array) {
              // If it's a Uint8Array, we need to convert it to Int16Array
              int16Array = new Int16Array(audioChunk.buffer, audioChunk.byteOffset, audioChunk.byteLength / 2);
          } else {
              console.error("AudioService: Unexpected audio chunk format:", audioChunk);
              return;
          }

          const float32Array = new Float32Array(int16Array.length);

          for (let i = 0; i < int16Array.length; i++) {
              float32Array[i] = int16Array[i] / 32768.0;
          }

          console.log("AudioService: Converted to Float32Array, length:", float32Array.length);

          return float32Array;
    }

    convertToFloat32(data) {
        const typedArray = new this.typedArray(data.buffer);
        const float32 = new Float32Array(typedArray.length);
        for (let i = 0; i < typedArray.length; i++) {
            float32[i] = typedArray[i] / this.maxValue;
        }
        return float32;
    }

    async playStoredAudio(messageId) {

        try {
            const storedAudio  = await getAudioMessage(messageId);
            if (storedAudio ) {  
                let audioData = this.convertAudioChunks(storedAudio);
                console.log("AudioService: Found stored audio ", {messageId});
                
                await this.resetAndPlayNew(messageId);

                let audioBuffer = this.buildAudioBuffer(audioData);        
                await this.playAudioBuffer(audioBuffer, messageId);

            } else {
              console.log('No stored audio found for message:', messageId);
            }    
        } catch (error) {
            console.error('Error fetching stored audio:', error);
        }
      }      

    async flush() {
        if (!this.samples.length) 
        {
            return;
        }
        
        let audioBuffer = this.buildAudioBuffer(this.samples);        
        await this.playAudioBuffer(audioBuffer, this.currentMessageId);
    }

    buildAudioBuffer(samples){
        const channels = this.options.channels;
        const length = samples.length / channels;
        const audioBuffer = this.audioContext.createBuffer(channels, length, this.options.sampleRate);

        for (let channel = 0; channel < channels; channel++) {
            const audioData = audioBuffer.getChannelData(channel);
            let offset = channel;
            for (let i = 0; i < length; i++) {
                audioData[i] = samples[offset];
                offset += channels;
            }
        }

        return audioBuffer;
    }

    async playAudioBuffer(audioBuffer, messageId){
        const bufferSource = this.audioContext.createBufferSource();
        bufferSource.buffer = audioBuffer;
        bufferSource.connect(this.gainNode);
        bufferSource.onended = () => {
            // Playback has finished
            this.state = 'stopped';
            this.emit('stop', messageId);
        };

        if (this.startTime < this.audioContext.currentTime) {
            this.startTime = this.audioContext.currentTime;
        }

        bufferSource.start(this.startTime);

        this.startTime += audioBuffer.duration;
        this.samples = new Float32Array();

        if (this.state !== 'playing') {
            this.state = 'playing';
            this.emit('play', messageId);
        }
    }

    play() { 
        if (this.state !== 'playing') {
            this.audioContext.resume();
            this.state = 'playing';           
            this.emit('play', this.currentMessageId);
        }
    }

    pause() {        
        if (this.state === 'playing') {
            this.audioContext.suspend();
            this.state = 'paused';            
            if(this.currentMessageId)   
                this.emit('pause', this.currentMessageId);
        }
    }

    stop() {
        this.audioContext.close();
        this.createContext();
        this.samples = new Float32Array();
        this.startTime = this.audioContext.currentTime;
        this.state = 'stopped';
        if(this.currentMessageId)   
            this.emit('stop', this.currentMessageId);
    }

    isAudioPlaying(messageId){        
        if(!this.currentMessageId || this.currentMessageId !== messageId)
            return false;

        if(this.state === 'playing')
            return true;

        return false;
    }

    getCurrentMessageId() {
        return this.currentMessageId;
    }

    // setVolume(volume) {
    //     this.gainNode.gain.value = volume;
    //     this.emit('volumeChanged', volume);
    // }
    setVolume(volume) {
        this.volume = volume;
        if (this.gainNode) {
            this.gainNode.gain.setValueAtTime(volume, this.audioContext.currentTime);
        }
        this.emit('volumeChanged', volume);
    }

    getCurrentVolume() {
        return this.volume;
    }

    getCurrentTime() {
        return this.audioContext.currentTime - this.startTime;
    }

    getDuration() {
        return this.samples.length / this.options.sampleRate;
    }

    destroy() {
        if (this.flushInterval) {
            clearInterval(this.flushInterval);
        }
        this.samples = null;
        this.audioContext.close();
        this.audioContext = null;
        this.emit('destroyed');
    }
}

export default new AudioService();