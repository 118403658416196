<template>
    <ion-page>
      <ion-header>
        <ion-toolbar>
          <ion-title>Settings</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content class="ion-padding">
        <ion-list>
          <ion-item>
            <ion-label>Native Language</ion-label>
            <ion-select v-model="settings.nativeLanguage" interface="popover" @ionChange="handleNativeLanguageChange">
              <ion-select-option value="other">Other</ion-select-option>
              <ion-select-option v-for="lang in supportedLanguages" :key="lang.code" :value="lang.code">
                {{ lang.name }}
              </ion-select-option>
            </ion-select>
          </ion-item>
  
          <ion-item>
            <ion-label>Target Language</ion-label>
            <ion-select v-model="settings.targetLanguage" interface="popover" @ionChange="handleTargetLanguageChange">
              <ion-select-option v-for="lang in supportedLanguages" :key="lang.code" :value="lang.code">
                {{ lang.name }}
              </ion-select-option>
            </ion-select>
          </ion-item>
  
          <ion-item>
            <ion-label>Language Level</ion-label>
            <ion-select v-model="settings.languageLevel" interface="popover">
              <ion-select-option value="Beginner">Beginner</ion-select-option>
              <ion-select-option value="Pre-Intermediate">Pre-Intermediate</ion-select-option>
              <ion-select-option value="Intermediate">Intermediate</ion-select-option>
              <ion-select-option value="Upper-Intermediate">Upper-Intermediate</ion-select-option>
              <ion-select-option value="Advanced">Advanced</ion-select-option>
            </ion-select>
          </ion-item>
  
          <ion-item>
            <ion-label>AI Voice</ion-label>
            <ion-select v-model="settings.aiVoice" interface="popover">
              <ion-select-option v-for="voice in filteredVoices" :key="voice.name" :value="voice.name">
                {{ voice.name }}
              </ion-select-option>
            </ion-select>
          </ion-item>
  
          <ion-item>
            <ion-label>Provide Feedback for Learning</ion-label>
            <ion-toggle v-model="settings.provideFeedback"></ion-toggle>
          </ion-item>
        </ion-list>
  
        <ion-button expand="block" @click="saveSettings">Save Settings</ion-button>
      </ion-content>
  
      <ion-toast
        :is-open="showToast"
        :message="toastMessage"
        :duration="3000"
        :color="toastColor"
        @didDismiss="showToast = false"
      ></ion-toast>
    </ion-page>
  </template>
  
  <script>
  import { ref, computed, onMounted } from 'vue';
  import { useStore } from 'vuex';
  import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonList, IonItem, IonLabel, IonSelect, IonSelectOption, IonToggle, IonButton, IonToast } from '@ionic/vue';
  
  export default {
    name: 'MySettings',
    components: {
      IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonList, IonItem, IonLabel, IonSelect, IonSelectOption, IonToggle, IonButton, IonToast
    },
    setup() {
      const store = useStore();
      const settings = ref({ ...store.getters['settings/getSettings'] });
      const showToast = ref(false);
      const toastMessage = ref('');
      const toastColor = ref('success');
  
      const supportedLanguages = [
        { code: 'en-US', name: 'English (US)' },

        { code: 'en-AU', name: 'English (Australia)' },
        { code: 'en-CA', name: 'English (Canada)' },
        { code: 'en-GB', name: 'English (United Kingdom)' },
        { code: 'en-IE', name: 'English (Ireland)' },
        { code: 'en-IN', name: 'English (India)' },

        { code: 'es-ES', name: 'Spanish (Spain)' },
        { code: 'fr-FR', name: 'French (France)' },
        { code: 'de-DE', name: 'German (Germany)' },
        { code: 'it-IT', name: 'Italian (Italy)' },
        { code: 'ja-JP', name: 'Japanese (Japan)' },
        { code: 'ko-KR', name: 'Korean (South Korea)' },
        { code: 'pt-BR', name: 'Portuguese (Brazil)' },
        { code: 'uk-UA', name: 'Ukrainian (Ukraine)' },
        { code: 'zh-CN', name: 'Chinese (Mandarin, Simplified)' }
      ];
  
      const voices = [
        { name: 'en-US-JennyNeural', language: 'en-US', gender: 'Female' },
        { name: 'en-US-GuyNeural', language: 'en-US', gender: 'Male' },
        { name: 'en-AU-NatashaNeural', language: 'en-AU', gender: 'Female' },
        { name: 'en-AU-WilliamNeural', language: 'en-AU', gender: 'Male' },
        { name: 'en-CA-ClaraNeural', language: 'en-CA', gender: 'Female' },
        { name: 'en-CA-LiamNeural', language: 'en-CA', gender: 'Male' },
        { name: 'en-GB-SoniaNeural', language: 'en-GB', gender: 'Female' },
        { name: 'en-GB-RyanNeural', language: 'en-GB', gender: 'Male' },
        { name: 'en-IE-EmilyNeural', language: 'en-IE', gender: 'Female' },
        { name: 'en-IE-ConnorNeural', language: 'en-IE', gender: 'Male' },
        { name: 'en-IN-AaravNeural', language: 'en-IN', gender: 'Male' },
        { name: 'en-IN-AashiNeural', language: 'en-IN', gender: 'Female' },

        { name: 'es-ES-ElviraNeural', language: 'es-ES' },
        { name: 'es-ES-AlvaroNeural', language: 'es-ES' },
        { name: 'fr-FR-DeniseNeural', language: 'fr-FR' },
        { name: 'fr-FR-HenriNeural', language: 'fr-FR' },
        { name: 'de-DE-KatjaNeural', language: 'de-DE' },
        { name: 'de-DE-ConradNeural', language: 'de-DE' },
        { name: 'it-IT-ElsaNeural', language: 'it-IT' },
        { name: 'it-IT-DiegoNeural', language: 'it-IT' },
        { name: 'ja-JP-NanamiNeural', language: 'ja-JP' },
        { name: 'ja-JP-KeitaNeural', language: 'ja-JP' },
        { name: 'ko-KR-SunHiNeural', language: 'ko-KR' },
        { name: 'ko-KR-InJoonNeural', language: 'ko-KR' },
        { name: 'pt-BR-FranciscaNeural', language: 'pt-BR', gender: 'Female' },
        { name: 'pt-BR-AntonioNeural', language: 'pt-BR' },
        { name: 'uk-UA-PolinaNeural', language: 'uk-UA', gender: 'Female' },
        { name: 'uk-UA-OstapNeural', language: 'uk-UA' },
        { name: 'zh-CN-XiaoxiaoNeural', language: 'zh-CN' },
        { name: 'zh-CN-YunxiNeural', language: 'zh-CN' }
      ];
  
      const filteredVoices = computed(() => {
        return voices.filter(voice => voice.language === settings.value.targetLanguage);
      });
  
      const displayToast = (message, color = 'success') => {
        toastMessage.value = message;
        toastColor.value = color;
        showToast.value = true;
      };
      
      const handleTargetLanguageChange = async (event) => {
      const selectedCode = event.detail.value;
      const selectedLanguage = supportedLanguages.find(lang => lang.code === selectedCode);
      if (selectedLanguage) {
        await store.dispatch('settings/setTargetLanguage', {
          code: selectedLanguage.code,
          name: selectedLanguage.name
        });        
        settings.value = { ...store.getters['settings/getSettings'] };
      }
    };

    const handleNativeLanguageChange = async (event) => {
      const selectedCode = event.detail.value;
      const selectedLanguage = supportedLanguages.find(lang => lang.code === selectedCode);
      
        await store.dispatch('settings/setNativeLanguage', {
          code: selectedLanguage?selectedLanguage.code:null,
          name: selectedLanguage?selectedLanguage.name:null
        });
        settings.value = { ...store.getters['settings/getSettings'] };
      
    };

      const saveSettings = async () => {
        try {
          await store.dispatch('settings/updateSettings', settings.value);
          displayToast('Settings saved successfully');
        } catch (error) {
          console.error('Error saving settings:', error);
          displayToast('Failed to save settings', 'danger');
        }
      };
  
      onMounted(async () => {
        try {
          // await store.dispatch('settings/fetchSettings');
          settings.value = { ...store.getters['settings/getSettings'] };
          
          // Set default value to 'other' if nativeLanguage is not in the list
          if (!settings.value.nativeLanguage || !supportedLanguages.some(lang => lang.code === settings.value.nativeLanguage)) {
            settings.value.nativeLanguage = 'other';
          }
        } catch (error) {
          console.error('Error fetching settings:', error);
          displayToast('Failed to load settings', 'danger');
        }
      });
  
      return {
        settings,
        supportedLanguages,
        filteredVoices,
        saveSettings,
        showToast,
        toastMessage,
        toastColor,
        handleTargetLanguageChange,
        handleNativeLanguageChange
      };
    }
  };
  </script>