import axios from 'axios';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';

const state = {
  usage: {
    tts_to_sync: 0,
    tts_accumulated: 0,
    stt_to_sync: 0,
    stt_accumulated: 0,
    last_update: null
  },
  limits: {
    tts: 1000000, // 1M characters
    stt: 18000,   // 5 hours in seconds
    reset_date: null
  }
};

const mutations = {
  SET_USAGE(state, usage) {
    state.usage = usage;
  },
  SET_LIMITS(state, limits) {
    state.limits = limits;
  },
  INCREMENT_TTS(state, count) {
    state.usage.tts_to_sync += count;
  },
  INCREMENT_STT(state, count) {
    state.usage.stt_to_sync += count;
  }
};

const actions = {
  async getUsage({ commit, rootState }) {
    const userId = rootState.account.user.uid;
    try {
      const response = await axios.get(`${process.env.VUE_APP_API_BASE_URL}/get_usage/${userId}`);
      commit('SET_USAGE', response.data);
    } catch (error) {
      console.error('Error fetching usage:', error);
    }
  },

  async incrementTtsUsage({ commit, rootState }, count) {
    commit('INCREMENT_TTS', count);
    const userId = rootState.account.user.uid;
    try {
      const response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/update_tts`, {
        userId,
        count
      });
      commit('SET_USAGE', response.data);
    } catch (error) {
      console.error('Error updating TTS usage:', error);
    }
  },

  async incrementSttUsage({ commit, rootState }, count) {
    commit('INCREMENT_STT', count);
    const userId = rootState.account.user.uid;
    try {
      const response = await axios.post(`${process.env.VUE_APP_API_BASE_URL}/update_stt`, {
        userId,
        count
      });
      commit('SET_USAGE', response.data);
    } catch (error) {
      console.error('Error updating STT usage:', error);
    }
  },

  async fetchLimits({ commit, rootState }) {
    const userId = rootState.account.user.uid;
    const userDocRef = doc(db, 'users', userId);
    const userDocSnap = await getDoc(userDocRef);
    
    if (userDocSnap.exists() && userDocSnap.data().limits) {
      commit('SET_LIMITS', userDocSnap.data().limits);
    } else {
      // If limits don't exist, initialize them
      const newLimits = {
        tts: 1000000, // 1M characters
        stt: 18000,   // 5 hours in seconds
        reset_date: new Date(new Date().setHours(0, 0, 0, 0) + 30 * 24 * 60 * 60 * 1000).toISOString() // 30 days from now at midnight
      };
      await setDoc(userDocRef, { limits: newLimits }, { merge: true });
      commit('SET_LIMITS', newLimits);
    }
  }
};

const getters = {
  isTtsAvailable: (state) => {
    return (state.usage.tts_to_sync + state.usage.tts_accumulated) < state.limits.tts;
  },
  isSttAvailable: (state) => {
    return (state.usage.stt_to_sync + state.usage.stt_accumulated) < state.limits.stt;
  },
  getTtsUsage: (state) => {
    return state.usage.tts_to_sync + state.usage.tts_accumulated;
  },
  getSttUsage: (state) => {
    return state.usage.stt_to_sync + state.usage.stt_accumulated;
  },
  getTtsLimit: (state) => state.limits.tts,
  getSttLimit: (state) => state.limits.stt,
  getResetDate: (state) => state.limits.reset_date
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};