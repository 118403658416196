// src/store/modules/exercise.js

import { collection, query, where, getDocs, orderBy } from 'firebase/firestore';
import { db } from '../../firebase';  // Adjust the import path as needed
import { v4 as uuidv4 } from 'uuid';

const state = {
    exercises: [],
    userExercises: [],
    selectedExercise: null,
    loading: false,
    error: null,
    chats: {},  // Will store chat data for different contexts
    lastMessages: {},  // New state to track last messages for each context
    mode: null, // New state for the mode
  };

const mutations = {
  SET_EXERCISES(state, exercises) {
    state.exercises = exercises;
  },
  SET_USER_EXERCISES(state, exercises) {
    state.userExercises = exercises;
  },
  SET_SELECTED_EXERCISE(state, exercise) {
    state.selectedExercise = exercise;
  },
  SET_LOADING(state, isLoading) {
    state.loading = isLoading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_MODE(state, mode) {
    state.mode = mode;
  },
  SET_CHAT_DATA(state, { context, chatId, started, mode }) {
    state.chats[context] = {
      chatId,
      chatHistory: [],
      started: started || false
    };   
    state.mode = mode; 
  },
  ADD_MESSAGE_TO_CHAT(state, { context, message }) {
    if (!state.chats[context]) {
      state.chats[context] = {
        chatId: null,
        chatHistory: [],
        started: false
      };
    }
    const messageWithId = { ...message, id: uuidv4() };
    state.chats[context].chatHistory.push(messageWithId);
    state.lastMessages[context] = messageWithId;
  }
};

const actions = {
  async loadUserExercises({ commit, rootGetters }) {
    commit('SET_LOADING', true);
    commit('SET_ERROR', null);
    try {
      const userId = rootGetters['account/currentUser'].uid;
      const exercisesCollection = collection(db, 'exercises');
      const exercisesQuery = query(
        exercisesCollection, 
        where('userId', '==', userId),
        orderBy('name')
      );
      const exercisesSnapshot = await getDocs(exercisesQuery);
      const exercises = exercisesSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      commit('SET_USER_EXERCISES', exercises);
    } catch (error) {
      console.error("Error loading user exercises:", error);
      commit('SET_ERROR', "Failed to load exercises. Please try again.");
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async fetchExercises({ commit }) {
    commit('SET_LOADING', true);
    commit('SET_ERROR', null);
    try {
      const exercisesCollection = collection(db, 'exercises');
      const exerciseSnapshot = await getDocs(exercisesCollection);
      const exercises = exerciseSnapshot.docs.map(doc => ({
        id: doc.id,
        name: doc.data().name,
        systemPrompt: doc.data().systemPrompt
      }));
      commit('SET_EXERCISES', exercises);
    } catch (error) {
      console.error("Error fetching exercises:", error);
      commit('SET_ERROR', "Failed to load exercises. Please try again.");
    } finally {
      commit('SET_LOADING', false);
    }
  },
  selectExercise({ commit }, exercise) {
    commit('SET_SELECTED_EXERCISE', exercise);
  },
  async startNewChat({ commit, rootGetters }, { context, exerciseId }) {
    commit('SET_LOADING', true);
    commit('SET_ERROR', null);
    try {
      const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/start_new_chat`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: rootGetters['account/currentUser'].uid,
          exerciseId: exerciseId
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to start chat');
      }

      const data = await response.json();
      
      // Create an initial message if the backend sends a non-empty response
      let initialChatHistory = [];
      if (data.response && data.response.trim() !== '') {
        const initialMessage = {
          id: uuidv4(),
          role: 'assistant',
          content: data.response
        };
        initialChatHistory.push(initialMessage);
      }

      commit('SET_CHAT_DATA', {
        context,
        chatId: data.chatId,        
        started: true,
        mode: data.mode
      });

      // If there was an initial message, trigger it to be played and stored
      if (initialChatHistory.length > 0) {
        commit('ADD_MESSAGE_TO_CHAT', { 
          context, 
          message: initialChatHistory[0]
        });
      }

    } catch (error) {
      console.error("Error starting chat:", error);
      commit('SET_ERROR', "Failed to start chat. Please try again.");
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async startNewFreeStyleChat({ commit, rootGetters }, { context }) {
    commit('SET_LOADING', true);
    commit('SET_ERROR', null);
    try {
      const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/start_new_free_style`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: rootGetters['account/currentUser'].uid
        }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to start free-style chat');
      }
  
      const data = await response.json();
      
      commit('SET_CHAT_DATA', {
        context,
        chatId: data.chatId,        
        started: true,
      });
  
      if (data.response && data.response.trim() !== '') {
        commit('ADD_MESSAGE_TO_CHAT', { 
          context, 
          message: {
            id: uuidv4(),
            role: 'assistant',
            content: data.response
          }
        });
      }
  
    } catch (error) {
      console.error("Error starting free-style chat:", error);
      commit('SET_ERROR', "Failed to start free-style chat. Please try again.");
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async switchExerciseMode({ commit, state, rootGetters }, {context ,exerciseId, newMode }) {
    commit('SET_LOADING', true);
    commit('SET_ERROR', null);
    try {
      const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/switch_exercise_mode`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          exerciseId: exerciseId,
          userId: rootGetters['account/currentUser'].uid,
          chatId: state.chats[context]?.chatId,
          newMode: newMode,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to switch exercise mode');
      }

      const data = await response.json();

      commit('SET_CHAT_DATA', {
        context: context,
        chatId: data.chatId,
        started: true,
        mode: newMode
      });

      if (data.response && data.response.trim() !== '') {
        commit('ADD_MESSAGE_TO_CHAT', {
          context: context,
          message: {
            id: uuidv4(),
            role: 'assistant',
            content: data.response
          }
        });
      }

    } catch (error) {
      console.error("Error switching exercise mode:", error);
      commit('SET_ERROR', "Failed to switch exercise mode. Please try again.");
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async sendMessage({ commit, state, rootGetters }, { context, message }) {
    commit('SET_LOADING', true);
    commit('SET_ERROR', null);
    try {
      const chatData = state.chats[context];
      if (!chatData || !chatData.chatId) {
        throw new Error('Chat not initialized');
      }

      const response = await fetch(`${process.env.VUE_APP_API_BASE_URL}/talk_to_chat`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: rootGetters['account/currentUser'].uid,
          chatId: chatData.chatId,
          message: message
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to send message');
      }
      const data = await response.json();
      commit('ADD_MESSAGE_TO_CHAT', { context, message: { role: 'user', content: message } });
      commit('ADD_MESSAGE_TO_CHAT', { context, message: { role: 'assistant', content: data.response } });
    } catch (error) {
      console.error("Error sending message:", error);
      commit('SET_ERROR', "Failed to send message. Please try again.");
    } finally {
      commit('SET_LOADING', false);
    }
  }
};

const getters = {
  allExercises: state => state.exercises,
  selectedExercise: state => state.selectedExercise,
  isLoading: state => state.loading,
  error: state => state.error,
  chatData: state => context => state.chats[context] || { chatId: null, chatHistory: [], started: false },
  chatStarted: state => context => state.chats[context]?.started || false,
  chatHistory: state => context => state.chats[context]?.chatHistory || [],
  lastMessage: state => context => state.lastMessages[context] || null,
  currentMode: state => state.mode,
  userExercises: state => state.userExercises,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};