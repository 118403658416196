<template>
    <div class="voice-recording">
      <div class="recording-controls">
        <ion-button size="small" @click="toggleRecording" :color="isRecording ? 'danger' : 'primary'">
          <ion-icon :icon="isRecording ? stopCircle : micOutline" slot:start></ion-icon>
          {{ isRecording ? 'Stop' : 'Record' }}
        </ion-button>
        <div v-if="isRecording" class="volume-meter">
          <div class="volume-bar" :style="{ width: volumeWidth + '%' }"></div>
        </div>
      </div>
      <p v-if="statusMessage" :class="{ 'error-message': isError, 'info-message': !isError }">
        {{ statusMessage }}
      </p>
    </div>
  </template>
  
  <script>
  import { computed } from 'vue'
  import { useStore } from 'vuex';
  import { ref, onUnmounted } from 'vue';
  import { IonButton, IonIcon } from '@ionic/vue';
  import { micOutline, stopCircle } from 'ionicons/icons';
  
  export default {
    name: 'VoiceRecording',
    props: {
        context: {
        type: String,
        required: true
        }
    },
    components: { IonButton, IonIcon },
    emits: ['transcriptionComplete', 'error'],
    setup(props, { emit }) {
      const store = useStore();
      const isRecording = ref(false);
      const mediaRecorder = ref(null);
      const audioChunks = ref([]);
      const statusMessage = ref('');
      const isError = ref(false);
      const volumeWidth = ref(0);
      let audioContext = null;
      let analyser = null;
      let dataArray = null;
      let animationFrameId = null;
      const settings = computed(() => store.getters['settings/getSettings'])
      const recordingDuration = ref(0);
      const isSttAvailable = computed(() => store.getters['usageLimits/isSttAvailable']);
  
      const toggleRecording = () => {
        if (isRecording.value) {
          stopRecording();
        } else {
          startRecording();
        }
      };
  
      const startRecording = async () => {
        try {
          const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
          mediaRecorder.value = new MediaRecorder(stream);
          
          mediaRecorder.value.ondataavailable = (event) => {
            audioChunks.value.push(event.data);
          };
  
          mediaRecorder.value.onstop = validateUsageAndSendAudioToAPI;
  
          mediaRecorder.value.start();
          isRecording.value = true;
          statusMessage.value = '';
          isError.value = false;
  
          // Set up audio analysis for volume meter
          audioContext = new (window.AudioContext || window.webkitAudioContext)();
          analyser = audioContext.createAnalyser();
          const source = audioContext.createMediaStreamSource(stream);
          source.connect(analyser);
          analyser.fftSize = 256;
          const bufferLength = analyser.frequencyBinCount;
          dataArray = new Uint8Array(bufferLength);
  
          updateVolumeMeter();
        } catch (error) {
          console.error('Error starting recording:', error);
          statusMessage.value = 'Failed to start recording. Please check your microphone permissions.';
          isError.value = true;
          emit('error', 'Failed to start recording');
        }
      };
  
      const stopRecording = () => {
        if (mediaRecorder.value && mediaRecorder.value.state !== 'inactive') {
          mediaRecorder.value.stop();
          isRecording.value = false;
          if (audioContext) {
            audioContext.close();
            audioContext = null;
          }
          if (animationFrameId) {
            cancelAnimationFrame(animationFrameId);
          }
        }
      };
  
      const updateVolumeMeter = () => {
        if (!isRecording.value) return;
  
        analyser.getByteFrequencyData(dataArray);
        const average = dataArray.reduce((acc, value) => acc + value, 0) / dataArray.length;
        volumeWidth.value = Math.min(100, average * 2); // Scale up for better visibility
  
        animationFrameId = requestAnimationFrame(updateVolumeMeter);
      };
      
      const validateUsageAndSendAudioToAPI = async() =>{
        const audioBlob = new Blob(audioChunks.value, { type: 'audio/webm' });
        
        // Update STT usage with accurate duration          
        const audioDuration = await getAudioDuration(audioBlob);
        // Get accurate duration from the audio buffer
        recordingDuration.value = audioDuration;
        await store.dispatch('usageLimits/incrementSttUsage', Math.ceil(recordingDuration.value));

        if(isSttAvailable.value)
          return await sendAudioToAPI(audioBlob)
      }

      const sendAudioToAPI = async (audioBlob) => {
        try {
          // Convert to WAV
          const audioContext = new (window.AudioContext || window.webkitAudioContext)();
          const audioBuffer = await audioContext.decodeAudioData(await audioBlob.arrayBuffer());
          
          // Resample to 16kHz
          const offlineCtx = new OfflineAudioContext(1, audioBuffer.duration * 16000, 16000);
          const source = offlineCtx.createBufferSource();
          source.buffer = audioBuffer;
          source.connect(offlineCtx.destination);
          source.start();
          const resampled16kHzBuffer = await offlineCtx.startRendering();
          
          const wavBuffer = audioBufferToWav(resampled16kHzBuffer, { float32: false });
          const wavBlob = new Blob([wavBuffer], { type: 'audio/wav' });
          
          const formData = new FormData();
          formData.append('audio', wavBlob, 'recording.wav');
          formData.append('language', settings.value.targetLanguage??'en-US');
  
          const response = await fetch('https://voice-service-api-app.bravesky-5d60b5e6.francecentral.azurecontainerapps.io/transcribe', {
            method: 'POST',
            body: formData
          });
  
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
  
          const data = await response.json();
          console.log('API Response:', data);
  
          // //REMOVE THIS
          // //REMOVE THIS
          // //REMOVE THIS
          // if(data.text=== undefined || data.text==='')
          // {
          //   data.text='hello';
          // }

          if (data.text !== undefined) {
            if (data.text === '') {
              statusMessage.value = 'No speech detected. Please try again.';
              isError.value = false;
              store.dispatch('chat/setTranscribedText', { context: props.context, text: '' });
            } else {
              store.dispatch('chat/setTranscribedText', { context: props.context, text: data.text });             
              emit('transcriptionComplete', data.text);
              statusMessage.value = '';
              isError.value = false;
            }
          } else if (data.error) {
            store.dispatch('chat/setTranscribedText', { context: props.context, text: '' });
            throw new Error(data.error);
          } else {
            store.dispatch('chat/setTranscribedText', { context: props.context, text: '' });
            throw new Error(`Unexpected response from server: ${JSON.stringify(data)}`);
          }
        } catch (error) {
          store.dispatch('chat/setTranscribedText', { context: props.context, text: '' });
          console.error('Error in audio processing or API request:', error);
          statusMessage.value = `Error: ${error.message}`;
          isError.value = true;
          emit('error', error.message);
        }
  
        audioChunks.value = [];
      };

      // Function to get accurate audio duration
      const getAudioDuration = async (audioBlob) => {
        return new Promise((resolve, reject) => {
          const audioContext = new (window.AudioContext || window.webkitAudioContext)();
          const reader = new FileReader();
          reader.onload = async (e) => {
            try {
              const audioBuffer = await audioContext.decodeAudioData(e.target.result);
              resolve(audioBuffer.duration);
            } catch (err) {
              reject(err);
            } finally {
              audioContext.close();
            }
          };
          reader.onerror = (err) => reject(err);
          reader.readAsArrayBuffer(audioBlob);
        });
      };
  
      const audioBufferToWav = (buffer, opt) => {
        opt = opt || {};
  
        const numChannels = buffer.numberOfChannels;
        const sampleRate = buffer.sampleRate;
        const format = opt.float32 ? 3 : 1;
        const bitDepth = format === 3 ? 32 : 16;
  
        let result;
        if (numChannels === 2) {
          result = interleave(buffer.getChannelData(0), buffer.getChannelData(1));
        } else {
          result = buffer.getChannelData(0);
        }
  
        return encodeWAV(result, format, sampleRate, numChannels, bitDepth);
      };
  
      const encodeWAV = (samples, format, sampleRate, numChannels, bitDepth) => {
        const bytesPerSample = bitDepth / 8;
        const blockAlign = numChannels * bytesPerSample;
  
        const buffer = new ArrayBuffer(44 + samples.length * bytesPerSample);
        const view = new DataView(buffer);
  
        writeString(view, 0, 'RIFF');
        view.setUint32(4, 36 + samples.length * bytesPerSample, true);
        writeString(view, 8, 'WAVE');
        writeString(view, 12, 'fmt ');
        view.setUint32(16, 16, true);
        view.setUint16(20, format, true);
        view.setUint16(22, numChannels, true);
        view.setUint32(24, sampleRate, true);
        view.setUint32(28, sampleRate * blockAlign, true);
        view.setUint16(32, blockAlign, true);
        view.setUint16(34, bitDepth, true);
        writeString(view, 36, 'data');
        view.setUint32(40, samples.length * bytesPerSample, true);
        if (format === 1) { // Raw PCM
          floatTo16BitPCM(view, 44, samples);
        } else {
          writeFloat32(view, 44, samples);
        }
  
        return buffer;
      };
  
      const writeString = (view, offset, string) => {
        for (let i = 0; i < string.length; i++) {
          view.setUint8(offset + i, string.charCodeAt(i));
        }
      };
  
      const floatTo16BitPCM = (output, offset, input) => {
        for (let i = 0; i < input.length; i++, offset += 2) {
          const s = Math.max(-1, Math.min(1, input[i]));
          output.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7FFF, true);
        }
      };
  
      const writeFloat32 = (output, offset, input) => {
        for (let i = 0; i < input.length; i++, offset += 4) {
          output.setFloat32(offset, input[i], true);
        }
      };
  
      const interleave = (inputL, inputR) => {
        const length = inputL.length + inputR.length;
        const result = new Float32Array(length);
  
        let index = 0;
        let inputIndex = 0;
  
        while (index < length) {
          result[index++] = inputL[inputIndex];
          result[index++] = inputR[inputIndex];
          inputIndex++;
        }
        return result;
      };
  
      onUnmounted(() => {
      if (audioContext) {
        audioContext.close();
      }
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
    });

    return {
      isRecording,
      toggleRecording,
      micOutline,
      stopCircle,
      statusMessage,
      isError,
      volumeWidth
    };
  }
};
</script>

<style scoped>
.voice-recording {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 300px;
}

.recording-controls {
  display: flex;
  align-items: center;
  width: 100%;
}

.volume-meter {
  flex-grow: 1;
  height: 10px;
  background-color: #e0e0e0;
  border-radius: 5px;
  margin-left: 10px;
  overflow: hidden;
}

.volume-bar {
  height: 100%;
  background-color: #4caf50;
  transition: width 0.1s ease;
}

.error-message {
  color: red;
  margin-top: 5px;
  font-size: 0.8em;
}

.info-message {
  color: blue;
  margin-top: 5px;
  font-size: 0.8em;
}
</style>