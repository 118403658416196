<template>
  <div class="ion-padding">
    <template v-if="!currentUser">
      <h1>Welcome to Lobster</h1>
      <p>Please sign in or sign up to continue:</p>

      <ion-button expand="block" size="default" @click="signInWithGoogle">
        <ion-icon :icon="logoGoogle" slot:start></ion-icon>
        Sign in with Google
      </ion-button>

      <ion-button expand="block" @click="toggleEmailForm" v-if="!showEmailForm">
        <ion-icon :icon="mailOutline" slot:start></ion-icon>
        Sign in/up with Email
      </ion-button>

      <div v-if="showEmailForm">
        <form @submit.prevent="handleEmailAuth" class="ion-margin-top">
          <ion-item>            
            <ion-input label="Email" label-placement="floating" placeholder="Your Email" v-model="email" type="email" required></ion-input>
          </ion-item>
          <ion-item>
            <ion-input label="Password" label-placement="floating" placeholder="Your Password" v-model="password" type="password" required></ion-input>
          </ion-item>
          <ion-item v-if="isSignUp">
            <ion-input label="Confirm Password" label-placement="floating" placeholder="Confirm Your Password" v-model="confirmPassword" type="password" required></ion-input>
          </ion-item>
          <ion-button expand="block" type="submit" class="ion-margin-top" :disabled="isLoading">
            {{ isSignUp ? 'Sign Up' : 'Sign In' }}
            <ion-spinner v-if="isLoading" name="crescent"></ion-spinner>
          </ion-button>
        </form>
        
        <ion-button expand="block" fill="clear" @click="toggleAuthMode" :disabled="isLoading">
          {{ isSignUp ? 'Already have an account? Sign In' : 'Don\'t have an account? Sign Up' }}
        </ion-button>

        <ion-button expand="block" fill="clear" @click="toggleEmailForm" :disabled="isLoading">
          Back to Options
        </ion-button>
      </div>
    </template>

    <template v-else>
      <h2>Welcome, {{ currentUser.displayName || currentUser.email }}!</h2>
      <p>You're now signed in.</p>
      <p v-if="!currentUser.emailVerified" class="ion-text-center">
        Please verify your email address. 
        <ion-button fill="clear" @click="resendVerificationEmail">Resend verification email</ion-button>
      </p>

      <div v-if="userData">
        <h3>My Data:</h3>
        <ion-item>
          <ion-input 
            label="Name" 
            label-placement="floating" 
            placeholder="Your Name" 
            v-model="userName"
          ></ion-input>
        </ion-item>
        <ion-item>
          <ion-input 
            label="Bio" 
            label-placement="floating" 
            placeholder="Your Bio" 
            v-model="userBio"
          ></ion-input>
        </ion-item>
        <p>Created at: {{ formatDate(userData.createdAt) }}</p>
        <p>Last login: {{ formatDate(userData.lastLogin) }}</p>
        
        <ion-button expand="block" @click="updateUserInfo">Update Info</ion-button>
      </div>

      <ion-button expand="block" @click="signOut" class="ion-margin-top">
        <ion-icon :icon="logOutOutline" slot:start></ion-icon>
        Sign Out
      </ion-button>
    </template>

    <ion-toast
      :is-open="showToast"
      :message="toastMessage"
      :duration="3000"
      :color="toastColor"
      @didDismiss="showToast = false"
    ></ion-toast>
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue'
import { useStore } from 'vuex'
import { IonButton, IonIcon, IonToast, IonItem, IonInput, IonSpinner } from '@ionic/vue'
import { logoGoogle, mailOutline, logOutOutline } from 'ionicons/icons'
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth'

export default {
  name: 'MyAccount',
  components: {
    IonButton, IonIcon, IonToast, IonItem, IonInput, IonSpinner
  },
  setup() {
    const store = useStore()
    const auth = getAuth()
    
    const showToast = ref(false)
    const toastMessage = ref('')
    const toastColor = ref('success')
    const isLoading = ref(false)
    const isSignUp = ref(false)
    const showEmailForm = ref(false)
    const email = ref('')
    const password = ref('')
    const confirmPassword = ref('')
    const userBio = ref('')
    const userName = ref('')

    const currentUser = computed(() => store.getters['account/currentUser'])
    const userData = computed(() => store.getters['account/userData'])

    const displayToast = (message, color = 'success') => {
      toastMessage.value = message
      toastColor.value = color
      showToast.value = true
    }

    const signInWithGoogle = async () => {
      try {
        isLoading.value = true
        const message = await store.dispatch('account/signInWithGoogle')
        displayToast(message)
      } catch (error) {
        displayToast(error.message, 'danger')
      } finally {
        isLoading.value = false
      }
    }

    const handleEmailAuth = async () => {
      try {
        isLoading.value = true
        if (isSignUp.value) {
          if (password.value !== confirmPassword.value) {
            throw new Error('Passwords do not match')
          }
          const result = await createUserWithEmailAndPassword(auth, email.value, password.value)
          await sendEmailVerification(result.user)
          // Set default name for email sign-up
          await store.dispatch('account/updateUserData', { name: email.value.split('@')[0] })
          displayToast('Account created successfully. Please check your email to verify your account.')
        } else {
          await signInWithEmailAndPassword(auth, email.value, password.value)
          displayToast(`Welcome back, ${email.value}!`)
        }
      } catch (error) {
        console.error('Error with email authentication:', error)
        displayToast(error.message, 'danger')
      } finally {
        isLoading.value = false
      }
    }

    const toggleAuthMode = () => {
      isSignUp.value = !isSignUp.value
      email.value = ''
      password.value = ''
      confirmPassword.value = ''
    }

    const toggleEmailForm = () => {
      showEmailForm.value = !showEmailForm.value
      if (!showEmailForm.value) {
        // Reset form when hiding
        email.value = ''
        password.value = ''
        confirmPassword.value = ''
        isSignUp.value = false
      }
    }

    const signOut = async () => {
      try {
        const message = await store.dispatch('account/signOut')
        displayToast(message)
      } catch (error) {
        displayToast(error.message, 'danger')
      }
    }

    const resendVerificationEmail = async () => {
      try {
        const message = await store.dispatch('account/resendVerificationEmail')
        displayToast(message)
      } catch (error) {
        displayToast(error.message, 'danger')
      }
    }

    const updateUserInfo = async () => {
      try {
        await store.dispatch('account/updateUserData', { 
          name: userName.value,
          bio: userBio.value 
        })
        displayToast('User information updated successfully')
      } catch (error) {
        displayToast(error.message, 'danger')
      }
    }

    const formatDate = (date) => {
      if (!(date instanceof Date)) {
        return 'N/A'
      }
      return date.toLocaleString()
    }

    const initializeUsageLimits = async () => {
      await store.dispatch('usageLimits/getUsage', null, { root: true })
      await store.dispatch('usageLimits/fetchLimits', null, { root: true })
    }

    watch(currentUser, async (newUser, oldUser) => {
      if (newUser && !oldUser) {
        await initializeUsageLimits()
        // Set default name for Google sign-in
        if (newUser.displayName && !userData.value?.name) {
          await store.dispatch('account/updateUserData', { name: newUser.displayName })
        }
      }
    })

    watch(() => userData.value, (newUserData) => {
      if (newUserData) {
        userBio.value = newUserData.bio || ''
        userName.value = newUserData.name || ''
      }
    }, { immediate: true })

    return {
      currentUser,
      userData,
      signInWithGoogle,
      handleEmailAuth,
      signOut,
      resendVerificationEmail,
      showToast,
      toastMessage,
      toastColor,
      logoGoogle,
      mailOutline,
      logOutOutline,
      isSignUp,
      isLoading,
      showEmailForm,
      email,
      password,
      confirmPassword,
      toggleAuthMode,
      toggleEmailForm,
      userBio,
      userName,
      updateUserInfo,
      formatDate
    }
  }
}
</script>

<style scoped>
.ion-margin-top {
  margin-top: 16px;
}
</style>