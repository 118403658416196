export const platformService = {
    isIOS() {
        return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    },
    isAndroid() {
        return /Android/.test(navigator.userAgent);
    },
    isStandalone() {
        return (
            window.matchMedia('(display-mode: standalone)').matches ||
            window.navigator.standalone ||
            document.referrer.includes('android-app://')
        );
    },
    isSafari() {
        return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    },
    
    isMacOS() {
        return navigator.platform.toUpperCase().indexOf('MAC') >= 0;
    },
    
    isMacOSSafari() {
        return this.isSafari() && this.isMacOS();
    },
    
    isPWAInstallable() {
        return this.isIOS() || this.isAndroid() || this.isMacOSSafari() || 'BeforeInstallPromptEvent' in window;
    }
  };