<template>
    <div class="exercise-manager">
      <div class="header">
        <h2>Exercise Manager</h2>
        <div class="action-buttons">
          <ion-button fill="clear" @click="toggleNewExercise" :color="isCreatingNewExercise ? 'medium' : 'primary'">
            <ion-icon :icon="isCreatingNewExercise ? closeCircleOutline : addCircleOutline" slot:icon-only></ion-icon>
          </ion-button>
          <ion-button fill="clear" @click="confirmDelete" color="danger" :disabled="!selectedExerciseIds || isCreatingNewExercise">
            <ion-icon :icon="trashOutline" slot:icon-only></ion-icon>
          </ion-button>
        </div>
      </div>
  
      <!-- User selection dropdown for admin users -->
      <ion-item v-if="isAdmin">       
        <ion-select no-lines label="User" label-placement="stacked" placeholder="Select User" v-model="selectedUserId" @ionChange="loadUserExercises">
          <ion-select-option v-for="user in users" :key="user.id" :value="user.id">
            {{ getUserDisplayName(user) }}
          </ion-select-option>
        </ion-select>
      </ion-item>
  
      <!-- Exercise selection dropdown -->
      <ion-item v-if="!isCreatingNewExercise">        
        <ion-select no-lines label="Exercise" label-placement="stacked" placeholder="Select Exercise(s)" v-model="selectedExerciseIds" @ionChange="loadExercises" :multiple="isAdmin">
          <ion-select-option v-for="exercise in exercises" :key="exercise.id" :value="exercise.id">
            {{ exercise.name }}
          </ion-select-option>
        </ion-select>
      </ion-item>
  
      <!-- Exercise form card -->
      <ion-card v-if="isCreatingNewExercise || shouldShowForm">
        <ion-card-header>
          <ion-card-title>{{ isCreatingNewExercise ? 'Create New Exercise' : 'Edit Exercise' }}</ion-card-title>
          <ion-card-subtitle>{{ isCreatingNewExercise ? 'Fill in the details for a new exercise' : 'Modify the selected exercise' }}</ion-card-subtitle>
        </ion-card-header>
        <ion-card-content>
          <form @submit.prevent="saveExercise">
            <ion-item>              
              <ion-input label="Name" label-placement="floating" placeholder="Name of the exercise displayed in the system" v-model="currentExercise.name" required></ion-input>
            </ion-item>
            <ion-item>             
              <ion-input label="Topic" label-placement="floating" placeholder="E.g. Returning a faulty product and requesting a refund" v-model="currentExercise.topic" required></ion-input>
            </ion-item>
            <ion-item>
              <ion-textarea label="Goal" label-placement="floating" placeholder="E.g. Handle consumer issues effectively by being able to explain product faults, understand and assert consumer rights, negotiate with customer service representatives, and follow up on refund or exchange processes in various retail settings." v-model="currentExercise.goal" rows="4" required></ion-textarea>
            </ion-item>
            
            <div class="form-buttons">
              <ion-button type="submit">{{ isCreatingNewExercise ? 'Create' : 'Save' }}</ion-button>
              <ion-button type="button" fill="outline" @click="cancelEdit">Cancel</ion-button>
            </div>
          </form>
        </ion-card-content>
      </ion-card>
  
      <!-- Reassignment card for admin users -->
      <ion-card v-if="isAdmin && selectedExerciseIds && !isCreatingNewExercise">
        <ion-card-header>
          <ion-card-title>Reassign Exercises</ion-card-title>
          <ion-card-subtitle>Assign selected exercise(s) to another user</ion-card-subtitle>
        </ion-card-header>
        <ion-card-content>
          <ion-item>            
            <ion-select no-lines label="Assign to User" label-placement="stacked" placeholder="Assign to another User" v-model="reassignUserId">
              <ion-select-option v-for="user in users" :key="user.id" :value="user.id">
                {{ getUserDisplayName(user) }}
              </ion-select-option>
            </ion-select>
          </ion-item>
          
          <div class="form-buttons">
              <ion-button expand="block" @click="confirmReassign">Reassign Exercises</ion-button>
              <!-- <ion-button type="submit">{{ isCreatingNewExercise ? 'Create' : 'Save' }}</ion-button>
              <ion-button type="button" fill="outline" @click="cancelEdit">Cancel</ion-button> -->
            </div>
        </ion-card-content>
      </ion-card>
  
      <ion-toast
        :is-open="showToast"
        :message="toastMessage"
        :duration="3000"
        :color="toastColor"
        @didDismiss="showToast = false"
      ></ion-toast>
    </div>
  </template>
  
  <script>
  import { ref, computed, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import { IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonItem, IonSelect, IonSelectOption, IonInput, IonTextarea, IonButton, IonToast, IonIcon, alertController } from '@ionic/vue';
import { addCircleOutline, trashOutline, closeCircleOutline } from 'ionicons/icons';
import { collection, query, where, getDocs, doc, getDoc, setDoc, writeBatch } from 'firebase/firestore';
import { db } from '../../firebase';
  
  export default {
    name: 'ExerciseManager',
    components: {
        IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent, IonItem, IonSelect, IonSelectOption, IonInput, IonTextarea, IonButton, IonToast, IonIcon
    },
    setup() {
      const store = useStore();
      const isAdmin = computed(() => store.getters['account/isAdmin']);
      const currentUser = computed(() => store.getters['account/currentUser']);
      const toastColor = ref('success')
        
      const users = ref([]);
      const exercises = ref([]);
      const selectedUserId = ref('');
      const selectedExerciseIds = ref(null);
      const reassignUserId = ref('');
      const currentExercise = ref({
        name: '',
        topic: '',
        goal: '',
        userId: ''
      });
      const isCreatingNewExercise = ref(false);
  
      const showToast = ref(false);
      const toastMessage = ref('');
  
      const shouldShowForm = computed(() => {
    //   if (!isAdmin.value) return true;
      if (Array.isArray(selectedExerciseIds.value)) {
        return selectedExerciseIds.value.length === 1;
      }
      return !!selectedExerciseIds.value;
    });
  
      const loadUsers = async () => {
        if (isAdmin.value) {
          const usersCollection = collection(db, 'users');
          const usersSnapshot = await getDocs(usersCollection);
          users.value = usersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        }
      };

      const getUserDisplayName = (user) => {
        if(user.id === "JhpWuePl4jOLHwi3Shm15mKlxFG3")
            return "ladan.tania@gmail.com"
        if(user.id === "BCCtXDrAzoRfjsVN8c3CwfjAxJv1")
            return "olgabond.sr@gmail.com"
        if(user.id === "OFolMrbtxfTnrWm9zfQTrejTFpY2")
            return "olgabond.dec@gmail.com"
        if(user.id === "g6wBjE32ZdQSFw0lQ7pe5PMWcAS2")
            return "al18po05priv@gmail.com"
        return user.name || user.id || 'Unknown User';
      };
  
      const loadUserExercises = async () => {
        const userId = isAdmin.value ? selectedUserId.value : currentUser.value.uid;
        const exercisesCollection = collection(db, 'exercises');
        const exercisesQuery = query(exercisesCollection, where('userId', '==', userId));
        const exercisesSnapshot = await getDocs(exercisesQuery);
        exercises.value = exercisesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        selectedExerciseIds.value = null;
        clearForm();
      };
  
      const loadExercises = async () => {
        if (selectedExerciseIds.value) {
          if (Array.isArray(selectedExerciseIds.value) && selectedExerciseIds.value.length === 1) {
            selectedExerciseIds.value = selectedExerciseIds.value[0];
          }
          
          if (!Array.isArray(selectedExerciseIds.value)) {
            const exerciseDoc = doc(db, 'exercises', selectedExerciseIds.value);
            const exerciseSnapshot = await getDoc(exerciseDoc);
            if (exerciseSnapshot.exists()) {
              currentExercise.value = { id: exerciseSnapshot.id, ...exerciseSnapshot.data() };
            } else {
              console.log('No such document!');
              clearForm();
            }
          } else {
            clearForm();
          }
        } else {
          clearForm();
        }
      };
  
      const saveExercise = async () => {
        try {
          const exerciseData = { ...currentExercise.value };
        //   if (!isAdmin.value || isCreatingNewExercise.value) {
        //     exerciseData.userId = currentUser.value.uid;
        //   }
          exerciseData.userId = isAdmin.value ? selectedUserId.value : currentUser.value.uid
          const exerciseId = isCreatingNewExercise.value ? doc(collection(db, 'exercises')).id : selectedExerciseIds.value;
          await setDoc(doc(db, 'exercises', exerciseId), exerciseData);
          showToast.value = true;
          toastMessage.value = isCreatingNewExercise.value ? 'Exercise created successfully' : 'Exercise saved successfully';          
          toastColor.value = 'success'
          await loadUserExercises();
          clearForm();
          isCreatingNewExercise.value = false;
        } catch (error) {
          console.error('Error saving exercise:', error);
          showToast.value = true;
          toastMessage.value = 'Error saving exercise';
          toastColor.value = 'danger'
        }
      }; 
    
  
      const deleteExercises = async () => {
        if (selectedExerciseIds.value && (Array.isArray(selectedExerciseIds.value) ? selectedExerciseIds.value.length > 0 : true)) {
          try {
            const batch = writeBatch(db);
            const idsToDelete = Array.isArray(selectedExerciseIds.value) ? selectedExerciseIds.value : [selectedExerciseIds.value];
  
            idsToDelete.forEach((exerciseId) => {
              const exerciseRef = doc(db, 'exercises', exerciseId);
              batch.delete(exerciseRef);
            });
  
            await batch.commit();
            showToast.value = true;
            toastMessage.value = idsToDelete.length > 1 ? 'Exercises deleted successfully' : 'Exercise deleted successfully';
            toastColor.value = 'success'
            await loadUserExercises();
            clearForm();
          } catch (error) {
            console.error('Error deleting exercise(s):', error);
            showToast.value = true;
            toastMessage.value = `Error deleting exercise(s): ${error.message}`;
            toastColor.value = 'danger'
          }
        } else {
          console.error('No exercises selected for deletion');
          showToast.value = true;
          toastMessage.value = 'Please select at least one exercise to delete';
          toastColor.value = 'danger'
        }
      };
  
      const reassignExercises = async () => {
        if (selectedExerciseIds.value && reassignUserId.value) {
          try {
            const batch = writeBatch(db);
            const idsToReassign = Array.isArray(selectedExerciseIds.value) ? selectedExerciseIds.value : [selectedExerciseIds.value];
  
            idsToReassign.forEach((exerciseId) => {
              const exerciseRef = doc(db, 'exercises', exerciseId);
              batch.update(exerciseRef, { userId: reassignUserId.value });
            });
  
            await batch.commit();
            showToast.value = true;
            toastMessage.value = idsToReassign.length > 1 ? 'Exercises reassigned successfully' : 'Exercise reassigned successfully';
            toastColor.value = 'success'
            await loadUserExercises();
            clearForm();
          } catch (error) {
            console.error('Error reassigning exercise(s):', error);
            showToast.value = true;
            toastMessage.value = `Error reassigning exercise(s): ${error.message}`;
            toastColor.value = 'danger'
          }
        }
      };
      
      const toggleNewExercise = () => {
      isCreatingNewExercise.value = !isCreatingNewExercise.value;
      if (isCreatingNewExercise.value) {
        selectedExerciseIds.value = null;
        clearForm();
      }
    };

    const cancelEdit = () => {
      if (isCreatingNewExercise.value) {
        isCreatingNewExercise.value = false;
      }
      clearForm();
      selectedExerciseIds.value = null;
    };

      const clearForm = () => {
        currentExercise.value = {
          name: '',
          topic: '',
          goal: '',
          userId: isAdmin.value ? selectedUserId.value : currentUser.value.uid
        };
        reassignUserId.value = '';
      };
  
      const confirmDelete = async () => {
      const alert = await alertController.create({
        header: 'Confirm Deletion',
        message: `Are you sure you want to delete selected exercise(s)? It will NOT be possible to revert the operation.`,
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
          },
          {
            text: 'Delete',
            handler: () => deleteExercises()
          }
        ]
      });

      await alert.present();
    };

    const confirmReassign = async () => {
      const alert = await alertController.create({
        header: 'Confirm Reassigning',
        message: `Are you sure you want to reassign selected exercise(s)?`,
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
          },
          {
            text: 'Reassign',
            handler: () => reassignExercises()
          }
        ]
      });

      await alert.present();
    };

      onMounted(async () => {
        await loadUsers();
        if (isAdmin.value) {
          selectedUserId.value = users.value[0]?.id;
        }
        await loadUserExercises();
      });
  
      watch(selectedUserId, loadUserExercises);
  
      return {
        isAdmin,
      users,
      exercises,
      selectedUserId,
      selectedExerciseIds,
      reassignUserId,
      currentExercise,
      isCreatingNewExercise,
      shouldShowForm,
      showToast,
      toastMessage,
      loadUserExercises,
      loadExercises,
      saveExercise,
      toggleNewExercise,
      cancelEdit,
      deleteExercises,
      reassignExercises,
      addCircleOutline,
      trashOutline,
      closeCircleOutline,
      toastColor,
      confirmDelete,
      confirmReassign,
      getUserDisplayName,
      };
    }
  };
  </script>
  
  <style scoped>
  .exercise-manager {
    padding: 16px;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .header h2 {
    margin: 0;
  }
  
  .action-buttons {
    display: flex;
    gap: 10px;
  }
  
  ion-card {
    margin-bottom: 20px;
  }
  
  .form-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
  }
  
  ion-item {
    margin-bottom: 16px;
  }
  </style>