import { createStore } from 'vuex';
import accountModule from './modules/account';
import chatModule from './modules/chat';
import exerciseModule from './modules/exercise';
import settingsModule from './modules/settings';
import usageLimits from './modules/usageLimits';

export default createStore({
  modules: {
    account: accountModule,
    chat: chatModule,
    exercise: exerciseModule,
    settings: settingsModule,
    usageLimits: usageLimits,
  },
});