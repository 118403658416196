import { createApp } from 'vue'
import App from './App.vue'
import { IonicVue } from '@ionic/vue';
import store from '../store'; // Import the Vuex store
import { VueFire, VueFireAuth } from 'vuefire'
import { firebaseApp } from '../firebase'
import { platformService } from './services/platformService';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

import './registerServiceWorker'

import { 
  IonApp, 
  IonContent, 
  IonHeader, 
  IonToolbar, 
  IonTitle, 
  IonButtons, 
  IonButton, 
  IonIcon, 
  IonCard, 
  IonCardHeader, 
  IonCardTitle, 
  IonCardContent, 
  IonList, 
  IonItem, 
  IonLabel, 
  IonProgressBar} from '@ionic/vue';

let deferredPrompt;

window.addEventListener('beforeinstallprompt', (e) => {
  // Prevent Chrome 67 and earlier from automatically showing the prompt
  e.preventDefault();
  // Stash the event so it can be triggered later.
  deferredPrompt = e;
  // Optionally, send a custom event that your app can listen for to show an install button
  window.dispatchEvent(new CustomEvent('installable'));
});

const app = createApp(App)
  .use(IonicVue)
  .use(store) // Use the Vuex store
  .use(VueFire, {
    firebaseApp,
    modules: [
      VueFireAuth(),
    ],
  })

// Make deferredPrompt available in the Vue app
app.config.globalProperties.$deferredPrompt = deferredPrompt;
app.config.globalProperties.$platform = platformService;

// Register Ionic components
app.component('IonApp', IonApp);
app.component('IonContent', IonContent);
app.component('IonHeader', IonHeader);
app.component('IonToolbar', IonToolbar);
app.component('IonTitle', IonTitle);
app.component('IonButtons', IonButtons);
app.component('IonButton', IonButton);
app.component('IonIcon', IonIcon);

app.component('IonCard', IonCard);
app.component('IonCardHeader', IonCardHeader);
app.component('IonCardTitle', IonCardTitle);
app.component('IonCardContent', IonCardContent);
app.component('IonList', IonList);
app.component('IonItem', IonItem);
app.component('IonLabel', IonLabel);
app.component('IonProgressBar', IonProgressBar);

app.mount('#app');