<template>
  <div class="my-dashboard">
    <h1>My Dashboard</h1>
    <p>Welcome to your personal dashboard. Here you can view your progress and manage your account.</p>

    <ion-card>
      <ion-card-header>
        <ion-card-title>Usage Limits</ion-card-title>
      </ion-card-header>
      <ion-card-content>
        <ion-list>
          <ion-item lines="none">
            <ion-label>
              <h2>Text-to-Speech (TTS)</h2>
              <p>{{ formatNumber(ttsUsage) }} / {{ formatNumber(ttsLimit) }} characters</p>
            </ion-label>
          </ion-item>
          <ion-item >
            <ion-progress-bar :value="ttsUsagePercentage"></ion-progress-bar>
          </ion-item>
          
          <ion-item lines="none">
            <ion-label>
              <h2>Speech-to-Text (STT)</h2>
              <p>{{ formatTime(sttUsage) }} / {{ formatTime(sttLimit) }}</p>
            </ion-label>
          </ion-item>
          <ion-item >
            <ion-progress-bar :value="sttUsagePercentage"></ion-progress-bar>
          </ion-item>
        </ion-list>
        <p class="reset-date">Limits reset on: {{ formatDate(resetDate) }}</p>
      </ion-card-content>
    </ion-card>

    <div>
      <VoiceRecording 
        @transcriptionComplete="handleTranscription"
        @error="handleError"
        context="dashboard"
      />
      <p v-if="transcribedText">Transcribed Text: {{ transcribedText }}</p>
      <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import VoiceRecording from './VoiceRecording.vue';
import { IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonList, IonItem, IonLabel, IonProgressBar } from '@ionic/vue';

export default {
  name: 'MyDashboard',
  components: { 
    VoiceRecording, 
    IonCard, 
    IonCardHeader, 
    IonCardTitle, 
    IonCardContent, 
    IonList, 
    IonItem, 
    IonLabel, 
    IonProgressBar 
  },
  setup() {
    const store = useStore();
    const errorMessage = ref('');

    const transcribedText = computed(() => store.getters['chat/transcribedText']('dashboard'));

    // Usage limits and current usage
    const ttsUsage = computed(() => store.getters['usageLimits/getTtsUsage']);
    const sttUsage = computed(() => store.getters['usageLimits/getSttUsage']);
    const ttsLimit = computed(() => store.getters['usageLimits/getTtsLimit']);
    const sttLimit = computed(() => store.getters['usageLimits/getSttLimit']);
    const resetDate = computed(() => store.getters['usageLimits/getResetDate']);

    // Calculate usage percentages
    const ttsUsagePercentage = computed(() => ttsUsage.value / ttsLimit.value);
    const sttUsagePercentage = computed(() => sttUsage.value / sttLimit.value);

    const handleTranscription = (text) => {
      store.dispatch('chat/setTranscribedText', { context: 'dashboard', text });
      errorMessage.value = ''; // Clear any previous errors
    };

    const handleError = (error) => {
      errorMessage.value = `Error: ${error}`;
      store.dispatch('chat/setTranscribedText', { context: 'dashboard', text: '' });
    };

    const formatNumber = (number) => {
      return new Intl.NumberFormat().format(number);
    };

    const formatTime = (seconds) => {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      return `${hours}h ${minutes}m`;
    };

    const formatDate = (dateString) => {
      if (!dateString) return 'N/A';
      const date = new Date(dateString);
      return date.toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' });
    };

    return { 
      transcribedText, 
      errorMessage,
      handleTranscription,
      handleError,
      ttsUsage,
      sttUsage,
      ttsLimit,
      sttLimit,
      resetDate,
      ttsUsagePercentage,
      sttUsagePercentage,
      formatNumber,
      formatTime,
      formatDate
    };
  }
};
</script>

<style scoped>
.my-dashboard {
  padding: 20px;
}

.error-message {
  color: var(--ion-color-danger);
  margin-top: 10px;
}

ion-progress-bar {
  height: 14px;
  border-radius: 4px;
}

.reset-date {
  font-size: 0.9em;
  color: var(--ion-color-medium);
  margin-top: 16px;
  text-align: right;
}

ion-item {
  --padding-start: 0;
  --inner-padding-end: 0;
}
</style>