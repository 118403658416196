<template>
  <div v-if="showInstallUI">
    <ion-button size="small" color="primary" fill="outline" v-if="showInstallButton" @click="installPWA">
      <ion-icon :icon="downloadOutline" slot:start></ion-icon>
      Install App
    </ion-button>
    <div v-show="showIOSInstructions && showIOSInstructionsDiv" class="install-instructions">
      <p>To install this app on your iPhone:</p>
      <ol>
        <li>Tap the Share button</li>
        <li>Scroll down and tap "Add to Home Screen"</li>
      </ol>
      <ion-button fill="clear" size="default" @click="hideIOSInstructions">Ok</ion-button>
    </div>
    <div v-show="showMacOSSafariInstructions && showMacOSSafariInstructionsDiv" class="install-instructions">
      <p>To install this app on Safari for macOS:</p>
      <ol>
        <li>Click File in the menu bar</li>
        <li>Select "Add to Dock" from the dropdown</li>
        <li>Click "Add" in the confirmation dialog</li>
      </ol>
      <ion-button fill="clear" size="default" @click="hideMacOSSafariInstructions">Ok</ion-button>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import { IonButton, IonIcon } from '@ionic/vue';
import { downloadOutline } from 'ionicons/icons';
import { platformService } from '@/services/platformService';

export default {
  name: 'InstallPWA',
  components: { IonButton, IonIcon },
  setup() {
    const deferredPrompt = ref(null);
    const isIOS = computed(() => platformService.isIOS());
    const isMacOSSafari = computed(() => platformService.isMacOSSafari());
    const isStandalone = computed(() => platformService.isStandalone());
    const isPWAInstallable = computed(() => platformService.isPWAInstallable());

    const showIOSInstructionsDiv = ref(true);
    const showMacOSSafariInstructionsDiv = ref(true);

    const showInstallUI = computed(() => isPWAInstallable.value && !isStandalone.value);
    const showInstallButton = computed(() => !isIOS.value && !isMacOSSafari.value && deferredPrompt.value);
    const showIOSInstructions = computed(() => isIOS.value);
    const showMacOSSafariInstructions = computed(() => isMacOSSafari.value);

    onMounted(() => {
      window.addEventListener('beforeinstallprompt', (e) => {
        e.preventDefault();
        deferredPrompt.value = e;
      });
    });

    const installPWA = async () => {
      if (deferredPrompt.value) {
        deferredPrompt.value.prompt();
        const { outcome } = await deferredPrompt.value.userChoice;
        console.log(`User response to install prompt: ${outcome}`);
        deferredPrompt.value = null;
      }
    };

    const hideIOSInstructions = () => {
      showIOSInstructionsDiv.value = false;
    };

    const hideMacOSSafariInstructions = () => {
      showMacOSSafariInstructionsDiv.value = false;
    };

    return {
      showInstallUI,
      showInstallButton,
      showIOSInstructions,
      showMacOSSafariInstructions,
      showIOSInstructionsDiv,
      showMacOSSafariInstructionsDiv,
      installPWA,
      hideIOSInstructions,
      hideMacOSSafariInstructions,
      downloadOutline
    };
  }
};
</script>

<style scoped>
.install-instructions {
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 5px;
  margin-top: 10px;
}
</style>